import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, logo, mobileHero, viewMenu, menu } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={logo} alt="Logo" w="250px" mv="15px" />
          <CFImage src={mobileHero} alt="Logo" w="90%" mv="10px" />
          <CFView mt="10px" pulsate>
            <OrderPickupButton />
          </CFView>
          {/* <a href={menu} target="_blank">
            <CFView hover>
              <CFImage
                w="100%"
                src={viewMenu}
                alt="Order Pickup Button"
                maxWidth="370px"
              />
            </CFView>
          </a> */}
          <OrderDeliveryButton />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" zIndex={90} column justifyBetween alignCenter>
          <CFImage src={logo} alt="Logo" w="220px" mv="10px" />
          <CFImage src={hero} alt="Logo" w="90%" mv="10px" />
          <CFView column center>
            <CFView column justifyStart pv="10px">
              <CFText style={{ fontSize: 31 }} raleway bold>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView row center>
              {/* <a href={menu} target="_blank">
                <CFView hover>
                  <CFImage
                    w="100%"
                    src={viewMenu}
                    alt="Order Pickup Button"
                    maxWidth="280px"
                  />
                </CFView>
              </a> */}

              <OrderDeliveryButton />
              <CFView ml="20px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
